import { InformationCircle, QuestionCircle } from '@prenuvo/halo-icon';

import { DEFAULT_QUESTIONS } from '@/components/DefaultQuestions/constants';
import type { NavListProps } from '@/components/NavMenu/NavMenu.types';
import { NAV_OPTIONS } from '@/core/constants';
import { FORM_STRUCTURE_FULL_LIST, NECK_DESIRED_ORDER } from '@/mocks/constants';
import type { FormStructureSummary } from '@/store/useFormStructure/useFormStructure.type';
import type {
  ConditionDetailShowWhen,
  ConditionTemplate,
  Finding,
  FindingsByOrgan,
  RenderedSummary,
  ShowWhenCondition,
} from '@/types/finding';
import type { FormStructure, Organ } from '@/types/formStructure';
import type { Line } from '@/types/transcription';

import type {
  ComparisonOperator,
  FindingAttribute,
  MedicalItem,
  MissingField,
} from './utils.types';

export const pcmEncode = (input: Float32Array) => {
  const buffer = new ArrayBuffer(input.length * 2);
  const view = new DataView(buffer);

  for (let i = 0; i < input.length; i++) {
    const s = Math.max(-1, Math.min(1, input[i]));

    view.setInt16(i * 2, s < 0 ? s * 0x8000 : s * 0x7fff, true);
  }

  return buffer;
};

export function formatTranscriptionSentence(items: MedicalItem[] | undefined): string {
  if (!items) {
    return '';
  }

  let completeSentence = '';
  const symbols = ['.', ',', '!', '?', ':', ';', ')', '-', '(', '[', ']', '{', '}'];

  for (let i = 0; i < items.length; i++) {
    const { content } = items[i];

    if (content) {
      if (!symbols.includes(content)) {
        completeSentence += ` ${content.toLocaleLowerCase()}`;
      }
    }
  }

  return completeSentence;
}

export const addTranscriptionSymbols = (lines: Line[]) =>
  lines
    .map((line) => line.text)
    .join('')
    .replace(/(^|\b)\s*(open parenthesis|open paren)\.?\s*/gi, '(')
    .replace(/(^|\b)\s*(close parenthesis|close paren)\.?\s*/gi, ') ')
    .replace(/\.\s*\)/g, ')')
    .replace(/(^|\b)\s*(period)\.?\s*/gi, '. ')
    .replace(/(^|\b)\s*(comma)\.?\s*/gi, ', ')
    .replace(/\s{2,}/g, ' ');

export const selectedTextFromTextArea = (textArea: HTMLTextAreaElement) => {
  const start = textArea.selectionStart;
  const end = textArea.selectionEnd;

  return {
    end,
    start,
    textAreaSelected: textArea.value.substring(start, end),
  };
};

export const determineVariant = ({
  error,
  isFinishedFinding,
  isFocused,
  isTranscribing,
  text,
}: {
  error?: boolean;
  isFinishedFinding: boolean;
  isFocused: boolean;
  isTranscribing: boolean;
  text: string;
}) => {
  if (error) {
    return 'error';
  }

  if (isFinishedFinding) {
    return 'loading';
  }

  if (isFocused || isTranscribing || text !== '') {
    return 'focused';
  }

  return 'default';
};

export const transformStructureToNavList = (data: FormStructure): FormStructureSummary => {
  const transformedStructure: NavListProps[] = data?.structure?.map((item) => {
    const processedItem = { ...item };

    if (processedItem.key === 'neck' && processedItem.sub) {
      processedItem.sub = [...processedItem.sub].sort(
        (a, b) => NECK_DESIRED_ORDER.indexOf(a.key) - NECK_DESIRED_ORDER.indexOf(b.key),
      );
    }

    return {
      key: processedItem.key,
      disabled: processedItem.disabled,
      icon: NAV_OPTIONS[processedItem.key as keyof typeof NAV_OPTIONS] || QuestionCircle,
      progress: null,
      sub: processedItem.sub,
      title: processedItem.value,
    };
  });

  const ReasonForScanOption = {
    key: 'reason_for_scan',
    icon: InformationCircle,
    progress: null,
    title: 'Reason for a scan',
  };

  const favoriteOption = {
    key: 'favorite',
    icon: NAV_OPTIONS.favorite,
    progress: null,
    title: 'Final Impressions',
  };

  const keyImagesOption = {
    key: 'key_images',
    icon: NAV_OPTIONS.key_images,
    progress: null,
    title: 'Key Images',
  };

  const keyImagesRetrieveOption = {
    key: 'retrieve_key_images',
    icon: NAV_OPTIONS.retrieve_key_images,
    progress: null,
    title: 'Retrieve key images',
  };

  transformedStructure?.push(keyImagesRetrieveOption, favoriteOption, keyImagesOption);
  transformedStructure?.unshift(ReasonForScanOption);

  return {
    ...data,
    structure: transformedStructure,
  };
};

export const transformSnakeCaseToPascalCase = (str: string) =>
  str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

export const transformSentenceToSnakeCase = (str: string) =>
  str.trim().toLowerCase().replace(/\s+/g, '_');

export const formatTimestamp = (timestamp: number) => {
  if (timestamp === 0) {
    return 'Unknown Date';
  }

  const date = new Date(timestamp * 1000);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = String(date.getFullYear());

  return `${year}-${month}-${day}`;
};

export const convertTextToBulletPoints = (text: string): string => {
  if (!text) {
    return '';
  }

  if (text.includes('\n')) {
    const lines = text.split('\n').filter((line) => line.trim() !== '');

    if (lines.length === 0) {
      return '';
    }

    let result = '';
    let currentLevel = '';
    let hasLevels = false;

    lines.forEach((line) => {
      if (line.match(/([CTLS])(\d+)\/([CTLS])(\d+)/) || line.match(/Overall\s+\w+:/)) {
        if (currentLevel) {
          result += '</ol>';
        }

        currentLevel = line.trim();
        result += `<p>${currentLevel}</p><ol>`;
        hasLevels = true;
      } else {
        result += `<li data-list="bullet"><span class="ql-ui" contenteditable="false"></span>${line.trim()}</li>`;
      }
    });

    if (currentLevel) {
      result += '</ol>';
    }

    if (!hasLevels) {
      result = `<ol>${result}</ol>`;
    }

    return result;
  }

  return text;
};

export const findObjectByKeyValue = <T, K extends keyof T>(
  array: T[],
  key: K,
  value: T[K],
): T | undefined => array.find((obj) => obj[key] === value);

export const groupFindingsByOrgan = (data: RenderedSummary[]): { [key: string]: Finding[] } => {
  const groupedData: { [key: string]: Finding[] } = {};

  data.forEach((item) => {
    if (item.reportFindings.length === 0) {
      return;
    }

    const organ = item?.reportFindings[0]?.template?.organ || '';

    if (!groupedData[organ]) {
      groupedData[organ] = [];
    }

    const renderedSummaryData: Finding = {
      conditionTemplate: {
        condition: item.reportFindings[0].template?.condition,
        conditionDetail: item.reportFindings[0].template?.conditionDetail,
        normalStatementOutcome: item.reportFindings[0].template?.normalStatementOutcome,
        organ,
        uuid: item.reportFindings[0].template?.uuid,
      },
      renderedSummary: {
        actionDetails: item.actionDetails,
        actionSummary: item.actionSummary,
        csdScore: item.csdScore,
        findingDetails: item.findingDetails,
        findingSummary: item.findingSummary,
        keyImages: item.keyImages,
        newFinding: item.newFinding,
        oncoradsScore: item.oncoradsScore,
        reportFindings: item.reportFindings,
        reportFindingsUuids: item.reportFindingsUuids,
        reportUuid: item.reportUuid,
        status: '',
        uuid: item.uuid,
      },
    };

    groupedData[organ].push(renderedSummaryData);
  });

  return groupedData;
};

export const evaluateConditionShowWhen = (
  showWhen: ConditionDetailShowWhen,
  allValues: { [key: string]: unknown },
  organFindings: Finding[],
): boolean => {
  if (!showWhen || !organFindings) {
    return true;
  }

  const { condition, operator } = showWhen;

  if (operator === 'or' || operator === 'and') {
    if (!Array.isArray(condition)) {
      return true;
    }

    if (operator === 'or') {
      return condition.some((cond) =>
        evaluateConditionShowWhen({ condition: cond, operator: '===' }, allValues, organFindings),
      );
    }

    return condition.every((cond) =>
      evaluateConditionShowWhen({ condition: cond, operator: '===' }, allValues, organFindings),
    );
  }

  if (operator === 'not' && Array.isArray(condition)) {
    const { field } = condition[0];
    let fieldValue = allValues[field];

    if (fieldValue === undefined) {
      fieldValue = organFindings
        .map((finding) => finding.renderedSummary.reportFindings[0])
        .filter(Boolean)
        .flatMap((reportFinding) => reportFinding.conditionDetail)
        .find((detail) => detail?.name === field)?.value;
    }

    if (fieldValue === undefined || fieldValue === null || fieldValue === '') {
      return false;
    }

    return condition.every(
      (cond) =>
        !evaluateConditionShowWhen({ condition: cond, operator: '===' }, allValues, organFindings),
    );
  }

  const { field, value } = condition as ShowWhenCondition;

  let fieldValue = allValues[field];

  if (fieldValue === undefined) {
    fieldValue = organFindings
      .map((finding) => finding.renderedSummary.reportFindings[0])
      .filter(Boolean)
      .flatMap((reportFinding) => reportFinding.conditionDetail)
      .find((detail) => detail?.name === field)?.value;
  }

  switch (operator as ComparisonOperator) {
    case '===':
      return fieldValue === value;
    case 'any':
      return Array.isArray(value) ? value.includes(fieldValue as string) : false;
    case '>':
      return typeof fieldValue === 'number' && typeof value === 'number' && fieldValue > value;
    case '>=':
      return typeof fieldValue === 'number' && typeof value === 'number' && fieldValue >= value;
    case '<':
      return typeof fieldValue === 'number' && typeof value === 'number' && fieldValue < value;
    case '<=':
      return typeof fieldValue === 'number' && typeof value === 'number' && fieldValue <= value;
    case 'not':
      return fieldValue !== value;
    default:
      return true;
  }
};

export const categorizeFindings = (
  findings: Finding[],
): { commonFindings: Finding[]; defaultFindings: Finding[] } => {
  const defaultFindings: Finding[] = [];
  const commonFindings: Finding[] = [];

  findings.forEach((finding) => {
    if (finding.conditionTemplate.condition?.startsWith('default_question')) {
      defaultFindings.push(finding);
    } else {
      commonFindings.push(finding);
    }
  });

  return { commonFindings, defaultFindings };
};

export const findMissingRequiredDetails = (
  groupedData: {
    [key: string]: Finding[];
  },
  isReviewClicked: boolean = false,
): MissingField => {
  const result: MissingField = {};

  Object.keys(groupedData).forEach((key) => {
    const organData = groupedData[key] || [];

    if (DEFAULT_QUESTIONS?.[key] && isReviewClicked) {
      Object.entries(DEFAULT_QUESTIONS?.[key]).forEach(([question, questionData]) => {
        const organFindings = organData || [];
        const requiredQuestions = questionData?.required;

        if (requiredQuestions) {
          const hasMissingRequired = !organFindings.some(
            (finding) => finding.conditionTemplate.condition === question,
          );

          if (hasMissingRequired) {
            result[key] = { error: true, findingIndex: questionData.order, observationIndex: 0 };
          }
        }
      });
    }

    organData.forEach((item, findingIndex) => {
      const { conditionDetail = [] } = item.conditionTemplate;
      const { reportFindings } = item.renderedSummary;

      reportFindings.forEach((reportFinding, observationIndex) => {
        const { conditionDetail: conditionDetailData } = reportFinding;

        const requiredFields = conditionDetail.filter((detail) => {
          if (!detail.required) {
            return false;
          }

          if (!detail.showWhen) {
            return true;
          }

          return evaluateConditionShowWhen(
            detail.showWhen,
            conditionDetailData.reduce(
              (acc, curr) => ({
                ...acc,
                [curr.name]: curr.value,
              }),
              {},
            ),
            organData,
          );
        });

        if (requiredFields.length === 0) {
          return;
        }

        const isRequiredFieldsPresent = requiredFields.every((field) =>
          conditionDetailData.some(
            (detail) => detail.name === field.name && detail.value !== null && detail.value !== '',
          ),
        );

        if (!isRequiredFieldsPresent) {
          result[key] = { error: !isRequiredFieldsPresent, findingIndex, observationIndex };
        }
      });
    });
  });

  return result;
};

export const getOrgansWithHighScores = (data: {
  [key: string]: Finding[];
}): { [key: string]: Finding[] } => {
  const result: { [key: string]: Finding[] } = {};

  Object.keys(data).forEach((key) => {
    const organData = data[key];

    organData.forEach((item) => {
      const { csdScore, oncoradsScore } = item.renderedSummary;

      if (oncoradsScore === 4 || oncoradsScore === 5 || csdScore === 4 || csdScore === 5) {
        result[key] = [...(result[key] ?? []), item];
      }
    });
  });

  return result;
};

export const replaceConditionWithValue = (inputText: string): string => {
  const regex = /<{name: "[^"]+", value: (null|"[^"]+")(?:, uuid: "[^"]+")?\}>/g;

  return inputText.replace(regex, (_, p1) =>
    // If p1 is null, replace with an empty string
    p1 === 'null' ? '' : p1.replace(/^"|"$/g, ''),
  );
};

export const transformSentenceToKebabCase = (str: string) =>
  str
    .trim()
    .toLowerCase()
    .replace(/[\s,.&]+/g, '-')
    .replace(/[^a-z0-9-]/g, '');

export const generateListFromString = (value: string): { className: string; content: string }[] => {
  const liContent = value.match(/<li[^>]*>(.*?)(?=<li|$)/g);

  if (liContent === null) {
    return [{ className: '', content: value }];
  }

  return liContent.map((item) => {
    const classNameMatch = item.match(/class="([^"]*)"/);
    const className = classNameMatch ? classNameMatch[1] : '';
    const content = item
      .replace(/<li class="[^"]*">|<li>|<\/li>/g, '')
      .replace(/<b>/g, '<b class="text-neutral-200 dark:text-neutral-200">')
      .trim();

    return { className, content };
  });
};

export const calculateAge = (dob: string): number => {
  const today = new Date();
  const dobDate = new Date(dob);
  let age = today.getFullYear() - dobDate.getFullYear();
  const monthDiff = today.getMonth() - dobDate.getMonth();
  const dayDiff = today.getDate() - dobDate.getDate();

  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
    age--;
  }

  return age;
};

export const parseFindingAttributes = (findingDetails: string): FindingAttribute[] => {
  const regex =
    /<\{name\s*:\s*"(.*?)",\s*value\s*:\s*(?:"(.*?)"|null)(?:,\s*uuid\s*:\s*"(.*?)")?\}>/g;
  const placeholders: FindingAttribute[] = [];
  let match: null | RegExpExecArray;

  // eslint-disable-next-line no-cond-assign
  while ((match = regex.exec(findingDetails)) !== null) {
    const [fullMatch, name, value, uuid] = match;

    placeholders.push({
      endIndex: match.index + fullMatch.length,
      name,
      startIndex: match.index,
      uuid,
      value: value === 'None' ? null : value || null,
    });
  }

  return placeholders;
};

export const formatEditorString = (note: string) => note?.replace(/<[^>]*>?/g, '').trim();

export const parseOrganTitle = (organ: string) => {
  const organName = organ.split('_').join(' ');

  return organName.charAt(0).toUpperCase() + organName.slice(1);
};

export const getConditionTemplates = (
  findings: FindingsByOrgan,
): {
  [key: string]: ConditionTemplate[];
} =>
  Object.entries(findings).reduce<{ [key: string]: ConditionTemplate[] }>(
    (acc, [organ, organFindings]) => {
      if (organ === 'spine') {
        acc[organ] = organFindings
          .map((finding) => finding.conditionTemplate as ConditionTemplate)
          .filter((finding) => finding.condition !== 'Spondyloarthropathy');
      } else {
        acc[organ] = organFindings.map((finding) => finding.conditionTemplate as ConditionTemplate);
      }

      return acc;
    },
    {},
  );

export const mergeFindings = (
  navList: NavListProps[],
  defaultFindings: FindingsByOrgan,
  findings: FindingsByOrgan,
): FindingsByOrgan =>
  navList.reduce((acc, navData) => {
    const organKey = navData.key;
    const organFindings = findings[organKey] || [];
    const defaultOrganFindings = defaultFindings[organKey] || [];

    acc[organKey] = [...defaultOrganFindings, ...organFindings];

    if (navData.sub?.length) {
      navData.sub.forEach((subOrgan) => {
        const subOrganKey = subOrgan.key;
        const subOrganFindings = findings[subOrganKey] || [];
        const defaultSubOrganFindings = defaultFindings[subOrganKey] || [];

        acc[subOrganKey] = [...defaultSubOrganFindings, ...subOrganFindings].map((finding) => ({
          ...finding,
          parentOrgan: organKey,
        }));
      });
    }

    return acc;
  }, {} as FindingsByOrgan);

export const isDefaultQuestionEmptyContent = (
  findingDetails: string,
  findingSummary: string,
  actionDetails: string,
  actionSummary: string,
) =>
  (!findingDetails || findingDetails.trim() === '') &&
  (!findingSummary || findingSummary.trim() === '') &&
  (!actionDetails || actionDetails.trim() === '') &&
  (!actionSummary || actionSummary.trim() === '');

export const processFormStructure = (
  formStructure: { data: FormStructure[] },
  patientGender: string,
): FormStructure => {
  const highestFormStructuredVersion = formStructure.data.reduce((max, current) =>
    max.version > current.version ? max : current,
  );

  const missingOrgans = FORM_STRUCTURE_FULL_LIST.filter(
    (organ) =>
      !highestFormStructuredVersion.structure.some((organData) => organData.key === organ.key),
  );

  const genderSpecificOrgans = ['prostate', 'breasts', 'uterus', 'ovaries'];

  const updatedStructureData = highestFormStructuredVersion.structure
    .map((organ) => {
      if (genderSpecificOrgans.includes(organ.key.toLowerCase())) {
        if (patientGender === 'male') {
          return organ.key.toLowerCase() === 'prostate' ? organ : null;
        }

        return organ.key.toLowerCase() !== 'prostate' ? organ : null;
      }

      return organ;
    })
    .filter(Boolean)
    .concat(missingOrgans.map((organ) => ({ ...organ, disabled: true })));

  const updatedStructureDataForSub = updatedStructureData.map((organ) => {
    if (organ?.disabled && organ?.sub) {
      return {
        ...organ,
        sub: organ.sub.map((subOrgan) => ({ ...subOrgan, disabled: true })),
      };
    }

    return organ;
  });

  return {
    ...highestFormStructuredVersion,
    structure: updatedStructureDataForSub as Organ[],
  };
};

export const adjustHeight = (element: HTMLTextAreaElement) => {
  const elementHeight = 'auto';
  const elementStyle = element.style;

  elementStyle.height = elementHeight;
  const { scrollHeight } = element;

  if (scrollHeight > 88) {
    elementStyle.overflowY = elementHeight;
    elementStyle.height = '88px';
  } else {
    elementStyle.overflowY = 'hidden';
    elementStyle.height = `${scrollHeight}px`;
  }
};
