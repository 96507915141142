import { useMemo } from 'react';
import { IconSource } from '@prenuvo/halo-web';

import { FindingReport } from '@/components/Report/Components/Finding';
import { SectionData } from '@/components/Report/Components/Section';
import {
  getIconByFormStructure,
  getNormalStatementsWithDefaultTrue,
} from '@/components/Report/utils/Report.util';
import { ORGAN_SECTIONS } from '@/mocks/constants';
import { useReportOrgan } from '@/store';
import { useFormStructure } from '@/store/useFormStructure/useFormStructure';
import { Finding } from '@/types/finding';
import { FormStructure } from '@/types/formStructure';
import { ReportOrgan } from '@/types/shared';
import { parseOrganTitle } from '@/utils';

export const useTransformedReportData = (reportFindings: {
  [key: string]: Finding[];
}): SectionData[] => {
  const { formStructure, getFormDataByOrgan, getFormDataByOrganWithSub } = useFormStructure();

  const { reportOrgans } = useReportOrgan();
  const normalStatementData = Object.values(reportOrgans)[0] || {};

  const transformReportData = (reportFindingsData: { [key: string]: Finding[] }): SectionData[] =>
    Object.entries(ORGAN_SECTIONS).reduce((result, [section, organs]) => {
      const organsData = organs.reduce(
        (acc, organ) => {
          const findings = reportFindingsData[organ];

          const organFormData = getFormDataByOrgan(organ) || getFormDataByOrganWithSub(organ);

          const transformedFindings = findings?.map(
            (finding): FindingReport => ({
              actionDetails: finding.renderedSummary.actionDetails,
              actionSummary: finding.renderedSummary.actionSummary,
              csdScore: finding.renderedSummary.csdScore,
              error: null,
              findingDetails: finding.renderedSummary.findingDetails,
              findingSummary: finding.renderedSummary.findingSummary,
              keyImages:
                finding.renderedSummary.keyImages?.filter(
                  (image) => image.status !== 'deprecated',
                ) || [],
              observation: '',
              oncoradsScore: finding.renderedSummary.oncoradsScore,
              organ: finding.conditionTemplate.organ || '',
              progress: false,
              uuid: finding.renderedSummary.uuid,
            }),
          );

          if (organFormData) {
            acc.push({
              disabled: organFormData.disabled,
              findings: transformedFindings || [],
              icon: getIconByFormStructure(organ, formStructure as FormStructure),
              normals: getNormalStatementsWithDefaultTrue(
                normalStatementData[organ] as ReportOrgan,
              ),
              title: parseOrganTitle(organFormData.key),
            });
          }

          return acc;
        },
        [] as {
          disabled: boolean | undefined;
          findings: FindingReport[];
          icon: IconSource;
          normals: string[];
          title: string;
        }[],
      );

      if (organsData.length > 0) {
        result.push({
          organs: organsData,
          sectionName: section,
        });
      }

      return result;
    }, [] as SectionData[]);

  return useMemo(() => transformReportData(reportFindings), [reportFindings, formStructure]);
};
