import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { API_ENDPOINTS } from '@/core/api/endpoints';
import { makeApiRequest } from '@/core/api/makeApiRequest';
import { IS_DEV_OR_LOCAL_ENV } from '@/mocks/constants';
import { useFormStructure as useFormStructureStore } from '@/store/useFormStructure/useFormStructure';
import { FormStructureSummary } from '@/store/useFormStructure/useFormStructure.type';
import { usePatient } from '@/store/usePatient/usePatient';
import { FormStructure } from '@/types/formStructure';
import { processFormStructure, transformStructureToNavList } from '@/utils/utils';

export const useFormStructure = (studyId: string | undefined, skuID: string | undefined) => {
  const { setFormStructure } = useFormStructureStore();
  const { patient } = usePatient();
  const [formStructureData, setFormStructureData] = useState<FormStructureSummary>();

  const configOptions = !IS_DEV_OR_LOCAL_ENV ? !!studyId && !!skuID && !!patient : !!studyId;

  // TODO: temporary fix for SKU 360
  let skuIDToUse = skuID;

  if (skuID === '360') {
    skuIDToUse = '350';
  }

  if (IS_DEV_OR_LOCAL_ENV) {
    skuIDToUse = '300';
  }

  const fetchFormStructure = () =>
    makeApiRequest<FormStructure[]>(
      'FCT',
      `${API_ENDPOINTS.organs.getOrgans}?sku=${skuIDToUse}&status=draft`,
      'GET',
    );

  const { data: formStructure, isLoading } = useQuery({
    enabled: configOptions,
    queryFn: fetchFormStructure,
    queryKey: ['formStructure', studyId],
    refetchOnWindowFocus: false,
    retryOnMount: false,
    staleTime: Infinity,
  });

  useEffect(() => {
    const conditions = !IS_DEV_OR_LOCAL_ENV ? !!skuID && !!patient : true;

    if (formStructure && conditions) {
      const processedFormStructure = processFormStructure(formStructure, patient?.gender || 'male');
      const transformedData = transformStructureToNavList(processedFormStructure);

      setFormStructure(transformedData);
      setFormStructureData(transformedData);
    }
  }, [formStructure, patient, skuID]);

  return { formStructure: formStructureData, isLoading };
};
