import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { Report } from '@/components/Report/Report.type';
import { API_ENDPOINTS } from '@/core/api/endpoints';
import { makeApiRequest } from '@/core/api/makeApiRequest';
import { HttpMethod } from '@/core/constants';
import { useDefaultFinding, useFinding } from '@/store';
import { useFormStructure } from '@/store/useFormStructure/useFormStructure';
import { useReport as useReportStore } from '@/store/useReport/useReport';
import { RenderedSummary } from '@/types/finding';
import { categorizeFindings, groupFindingsByOrgan } from '@/utils/utils';

import { useAuth } from '../useAuth/useAuth';

export const useReport = () => {
  const { findings, setFindings } = useFinding();
  const { defaultFindings, setDefaultFindings } = useDefaultFinding();
  const { setReport } = useReportStore();
  const { user } = useAuth();
  const { id: studyId } = useParams<{ id: string }>();
  const { formStructure } = useFormStructure();
  const [reportData, setReportData] = useState<Report | undefined>();

  const fetchReport = () =>
    makeApiRequest<Report>(
      'FCT',
      `${API_ENDPOINTS.report.getReport}/${studyId}?user_id=${user?.user_id}`,
      HttpMethod.GET,
    );

  const fetchRenderedSummary = (uuid: string) =>
    makeApiRequest<RenderedSummary[]>(
      'FCT',
      `${API_ENDPOINTS.finding.renderedSummary}report/${uuid}`,
      HttpMethod.GET,
    );

  const { data: report, isLoading: isReportLoading } = useQuery({
    enabled: !!studyId && !!user?.user_id && !!formStructure?.uuid,
    queryFn: fetchReport,
    queryKey: ['report', studyId, user?.user_id, formStructure?.uuid],
  });

  const { data: renderedSummary, isLoading: isSummaryLoading } = useQuery({
    enabled: !!report?.data?.uuid && Object.keys(findings).length === 0,
    queryFn: () => fetchRenderedSummary(report!.data.uuid),
    queryKey: ['renderedSummary', report?.data.uuid],
    staleTime: Infinity,
  });

  useEffect(() => {
    if (report) {
      setReport(report.data);
      setReportData(report.data);
    }
  }, [report]);

  useEffect(() => {
    if (
      renderedSummary?.data &&
      renderedSummary.data.length > 0 &&
      Object.keys(findings).length === 0
    ) {
      const groupedData = groupFindingsByOrgan(renderedSummary.data);

      Object.keys(groupedData).forEach((organ) => {
        const { commonFindings, defaultFindings: defaultFindingsData } = categorizeFindings(
          groupedData[organ],
        );

        setFindings({
          ...findings,
          [organ]: commonFindings,
        });

        setDefaultFindings({
          ...defaultFindings,
          [organ]: defaultFindingsData,
        });
      });
    }
  }, [renderedSummary]);

  return { isLoading: isReportLoading || isSummaryLoading, report: reportData };
};
