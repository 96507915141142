import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import { API_ENDPOINTS } from '@/core/api/endpoints';
import { makeApiRequest } from '@/core/api/makeApiRequest';
import { useReportOrgan as useReportOrganStore } from '@/store/useReportOrgan';
import { ReportOrgan } from '@/types/shared';

export const useReportOrgan = (
  reportUuid: string,
  organName?: string,
  fetchByOrgan: boolean = false,
): {
  error: unknown;
  isLoading: boolean;
  reportOrgan: ReportOrgan | ReportOrgan[] | undefined;
} => {
  const { getReportOrgan, setReportOrgan, setReportOrgans } = useReportOrganStore();

  const fetchReportOrgan = () => {
    let endpoint: string;

    if (fetchByOrgan && organName) {
      endpoint = `${API_ENDPOINTS.reportOrgan.getReportOrganByUuid}/report/${reportUuid}/organ/${organName}`;
    } else {
      endpoint = `${API_ENDPOINTS.reportOrgan.getReportOrganByUuid}/report/${reportUuid}`;
    }

    return makeApiRequest<ReportOrgan>('FCT', endpoint, 'GET');
  };

  const { data, error, isLoading } = useQuery({
    enabled: !!reportUuid && (fetchByOrgan ? !!organName : true),
    queryFn: fetchReportOrgan,
    queryKey: fetchByOrgan ? ['reportOrgan', reportUuid, organName] : ['reportOrgan', reportUuid],
  });

  useEffect(() => {
    if (data?.data) {
      if (fetchByOrgan && organName) {
        setReportOrgan(reportUuid, organName, data.data);
      } else {
        setReportOrgans(reportUuid, data.data as unknown as ReportOrgan[]);
      }
    }
  }, [data, reportUuid, organName, fetchByOrgan, setReportOrgan]);

  return {
    error,
    isLoading,
    reportOrgan: fetchByOrgan ? getReportOrgan(reportUuid, organName!) || data?.data : data?.data,
  };
};
