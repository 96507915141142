import { create } from 'zustand';

import { Finding, UpdateFindingDetails } from '@/types/finding';

import { DefaultFindingsState } from './useDefaultFinding.types';

export const useDefaultFinding = create<DefaultFindingsState>((set, get) => ({
  addDefaultFinding: (finding: Finding, shouldHighlight = false) => {
    set((state) => {
      const updatedFindings = { ...state.defaultFindings };
      const organ = finding.conditionTemplate.organ || '';

      if (!updatedFindings[organ]) {
        updatedFindings[organ] = [];
      }

      updatedFindings[organ] = [...updatedFindings[organ], finding];

      return {
        ...state,
        defaultFindings: updatedFindings,
        highlightedFinding: shouldHighlight ? finding : state.highlightedFinding,
      };
    });

    return get().defaultFindings;
  },
  defaultFindings: {},
  deleteDefaultFinding: (organ: string, uuid: string) => {
    set((state) => {
      const organFindings = state.defaultFindings[organ] || [];
      const updatedFindings = organFindings.filter(
        (finding) => finding.renderedSummary.uuid !== uuid,
      );

      return {
        defaultFindings: {
          ...state.defaultFindings,
          [organ]: updatedFindings,
        },
      };
    });

    return get().defaultFindings;
  },
  getDefaultFindingsByOrgan: (organ: string) => get().defaultFindings[organ] || [],
  highlightedFinding: null,
  setDefaultFindings: (defaultFindings: { [key: string]: Finding[] }) => {
    set((state) => ({
      defaultFindings: {
        ...state.defaultFindings,
        ...defaultFindings,
      },
    }));
  },
  setHighlightedFinding: (finding: Finding) => {
    set({ highlightedFinding: finding });
  },
  updateDefaultFinding: (
    organ: string,
    updatedFinding: UpdateFindingDetails,
    renderedSummaryId: string,
  ) => {
    set((state) => {
      const organFindings = state.defaultFindings[organ]?.map((finding) => {
        const isSameUuid = finding.renderedSummary.uuid === renderedSummaryId;

        if (isSameUuid) {
          return {
            ...finding,
            ...updatedFinding,
          };
        }

        return finding;
      });

      return {
        ...state,
        defaultFindings: {
          ...state.defaultFindings,
          [organ]: organFindings || [updatedFinding],
        },
      };
    });

    return get().defaultFindings;
  },
}));
