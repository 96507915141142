import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AlignLeft } from '@prenuvo/halo-icon';
import { cn, Icon, Typography } from '@prenuvo/halo-web';

import { Editor } from '@/components/Editor';
import { Report } from '@/components/Report/Report.type';
import { useSaveTechniqueNotes } from '@/hooks/mutations/useSaveTechniqueNotes/useSaveTechniqueNotes';
import { useDebounce } from '@/hooks/useDebounce/useDebounce';
import { useOutsideClick } from '@/hooks/useOutsideClick/useOutsideClick';
import { useHiStudy } from '@/store';
import { usePatient } from '@/store/usePatient/usePatient';
import { useReport } from '@/store/useReport/useReport';
import { formatEditorString } from '@/utils/utils';

export function Technique({ isReadOnly = false }: { isReadOnly?: boolean }) {
  const { scanDetails } = useHiStudy();
  const { patient } = usePatient();
  const { report, setReport } = useReport();

  const [technique, setTechnique] = useState('');
  const [techniqueNotes, setTechniqueNotes] = useState('');
  const addTechniqueRef = useRef<HTMLDivElement>(null);
  const addTechniqueEditorRef = useRef<{ blur: () => void } | null>(null);
  const addNotesRef = useRef<HTMLDivElement>(null);
  const addNotesEditorRef = useRef<{ blur: () => void } | null>(null);
  const { id: studyId } = useParams<{ id: string }>();
  const mutation = useSaveTechniqueNotes(studyId || '');

  useEffect(() => {
    if (patient && scanDetails.technique && patient.gender) {
      if (report?.technique) {
        setTechnique(report.technique);
      } else {
        setTechnique(scanDetails.technique[patient.gender]);
      }
    }
  }, [scanDetails, patient]);

  const saveTechnique = async (type: string, updatedNote: string = '') => {
    if (updatedNote !== (type === 'technique' ? technique : techniqueNotes)) {
      try {
        const response = await mutation.mutateAsync({
          [type]: updatedNote,
        });

        if (response && report) {
          const { technique: updatedTechnique, techniqueNotes: updatedTechniqueNotes } =
            response as Report;
          const updatedReport = {
            ...report,
            technique: updatedTechnique,
            techniqueNotes: updatedTechniqueNotes,
          };

          setReport(updatedReport);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error saving technique notes:', error);
      }

      if (mutation.isSuccess) {
        if (type === 'technique') {
          setTechnique(updatedNote);
        } else {
          setTechniqueNotes(updatedNote);
        }
      }
    }
  };

  useEffect(() => {
    if (report) {
      const notesFromReport = report?.techniqueNotes;

      setTechniqueNotes(notesFromReport);
    }
  }, [report]);

  useOutsideClick([addNotesRef, addTechniqueRef], () => {
    if (addNotesEditorRef.current) {
      addNotesEditorRef.current.blur();
    }

    if (addTechniqueEditorRef.current) {
      addTechniqueEditorRef.current.blur();
    }
  });

  const debouncedUpdateNotes = useDebounce((value: string, type: string) => {
    saveTechnique(type, value);
  }, 500);

  return (
    <div className="space-y-4">
      <Typography
        as="div"
        data-testid="technique-title"
        variant={isReadOnly ? 'paragraph-lg-bold' : 'paragraph-md-bold'}
      >
        {isReadOnly ? 'TECHNIQUE' : 'Technique'}
      </Typography>
      <div className="space-y-4">
        {(!isReadOnly || formatEditorString(technique).length > 0) && (
          <div
            className={` ${!isReadOnly && 'rounded-md border border-neutral-800 p-2'}`}
            data-testid="technique-data"
          >
            {patient && scanDetails.technique && patient.gender ? (
              <div className="flex gap-2">
                {!isReadOnly && <Icon className="fill-neutral-400" size="md" source={AlignLeft} />}
                <div
                  className={cn('w-full', isReadOnly ? 'text-base' : 'text-sm')}
                  data-testid="technique-patient-result"
                  ref={addTechniqueRef}
                >
                  <Editor
                    isReadOnly={isReadOnly}
                    onChange={(value) => debouncedUpdateNotes(value, 'technique')}
                    placeholder="Type your technique here"
                    ref={addTechniqueEditorRef}
                    value={technique}
                  />
                </div>
              </div>
            ) : (
              <Typography>Not provided</Typography>
            )}
          </div>
        )}
        {(!isReadOnly || formatEditorString(techniqueNotes).length > 0) && (
          <div
            className={` ${!isReadOnly && 'rounded-md border border-neutral-800 p-2'}`}
            data-testid="add-notes-to-technique"
          >
            <div className="flex gap-2">
              {!isReadOnly && <Icon className="fill-neutral-400" size="md" source={AlignLeft} />}
              <div className="flex-1">
                <Typography as="div" variant={isReadOnly ? "paragraph-md" : "paragraph-xs"}>
                  <span className="text-neutral-400">
                    {isReadOnly ? 'Additional Technique Notes' : 'Add notes to technique'}
                  </span>
                </Typography>
                <div
                  className={cn('w-full', isReadOnly ? 'text-base' : 'text-sm')}
                  ref={addNotesRef}
                >
                  <Editor
                    isReadOnly={isReadOnly}
                    onChange={(value) => debouncedUpdateNotes(value, 'techniqueNotes')}
                    placeholder="Type your notes here"
                    ref={addNotesEditorRef}
                    value={techniqueNotes}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
