import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ChevronLeft, Document } from '@prenuvo/halo-icon';
import { Button, IconButton, Tooltip } from '@prenuvo/halo-web';

import { config } from '@/config';
import { usePatientStudies, useScanDetails } from '@/hooks/queries';
import { usePatientDetails } from '@/hooks/queries/usePatientDetails/usePatientDetails';
import { cn } from '@/lib/utils';
import { SHOW_SKU } from '@/mocks/constants';
import { useHiStudy } from '@/store';
import { useReport } from '@/store/useReport/useReport';
import { initializeTabManagement } from '@/utils';
import { calculateAge, formatTimestamp, transformSnakeCaseToPascalCase } from '@/utils/utils';

import { Logo } from '../../assets/icons';
import { HeaderProps } from './Header.types';

export function Header({ className }: HeaderProps) {
  const { id: studyId } = useParams();
  const { scanDetails } = useHiStudy();
  const { isPreviewMode, setPreviewMode } = useReport();
  const navigate = useNavigate();
  const location = useLocation();

  useScanDetails(studyId);

  const { machineID, patientID, scanDate, skuID } = scanDetails;
  const { patient } = usePatientDetails(patientID);
  const { previousStudyIds } = usePatientStudies(patientID, studyId, scanDate);

  const navigateBack = () => {
    navigate(-1);
    setPreviewMode(false);
  };

  useEffect(() => {
    if (location.pathname.includes('entry')) {
      setPreviewMode(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!studyId) {
      return;
    }

    initializeTabManagement({ studyId });
  }, [studyId]);

  const infoItem = (label: string, value?: string) => (
    <span key={label} className="border-r-2 px-2 py-1">
      {label.toLocaleLowerCase() !== 'package' && (
        <>
          <span data-testid={`patient-${label}`}>{label === 'DOB' ? `~ ${label}` : label}</span>
          <span className="px-1">
            <span className="relative bottom-0.5 inline-flex size-1 rounded-full bg-neutral-400 dark:bg-neutral-400" />
          </span>
        </>
      )}
      <span data-testid={`patient-${label}-value`}>{value}</span>
    </span>
  );

  const staticInfoMapping = {
    DOB: `${patient?.fake_dob || 'Unknown DoB'} - ~Age: ${
      patient?.fake_dob ? calculateAge(patient?.dob) : 'Unknown Age'
    }`,
    'Scan Date': formatTimestamp(scanDate),
  };

  const responsiveInfoMapping = {
    Identifier: studyId?.slice(0, 8),
    Package: SHOW_SKU(skuID.slice(-8)),
    Scanner: machineID || 'Unknown Scanner',
  };

  const responsiveList = (
    <div className="flex flex-col space-y-2">
      {Object.entries(responsiveInfoMapping).map(([label, value]) => (
        <span key={label} className="px-2 py-1">
          <span data-testid={`header-${label}`}>{label !== 'Package' && label}</span>
          {label !== 'Package' && (
            <span className="px-1">
              <span className="relative bottom-0.5 inline-flex size-1 rounded-full bg-neutral-400 dark:bg-neutral-400" />
            </span>
          )}
          <span data-testid={`header-${label}-value`}>{value}</span>
        </span>
      ))}
    </div>
  );

  return (
    <div
      className={cn(
        'flex h-16 rounded-t-2xl bg-stone-900 dark:bg-stone-900 px-4 text-neutral-400 dark:text-neutral-400',
        className,
      )}
      data-testid="header-container"
    >
      <div className="flex items-center">
        {isPreviewMode ? (
          <IconButton
            aria-label="Chevron left"
            data-testid="chevron-left-button"
            icon={ChevronLeft}
            onClick={navigateBack}
            size="sm"
            variant="text"
          />
        ) : (
          <img alt="Prenuvo Logo" data-testid="header-icon" src={Logo} />
        )}
      </div>
      <div className="flex flex-1 items-center pl-4">
        <span className="flex text-sm leading-none">
          <span className="border-r-2 py-1 pr-2" data-testid="patient-name">
            {patient?.fake_lastname && patient?.fake_firstname
              ? `${patient?.fake_lastname}, ${patient?.fake_firstname}`
              : 'Unknown Patient Name'}
          </span>
          <span className="border-r-2 px-2 py-1" data-testid="patient-gender">
            {patient?.gender ? transformSnakeCaseToPascalCase(patient?.gender) : 'Unknown Gender'}
          </span>
          {Object.entries(staticInfoMapping).map(([label, value]) => infoItem(label, value))}
          <div className="hidden xl:flex">
            {Object.entries(responsiveInfoMapping).map(([label, value]) => infoItem(label, value))}
          </div>
          <div className="flex cursor-pointer pl-2 xl:hidden" data-testid="patient-info-tooltip">
            <Tooltip content={responsiveList} side="bottom">
              <span className="rounded-md bg-stone-800 px-2 py-1">
                +{Object.entries(responsiveInfoMapping).length}
              </span>
            </Tooltip>
          </div>
        </span>
      </div>
      <div className="flex items-center">
        <Button
          aria-label="Medical History"
          className={{ root: 'mr-4 bg-stone-700' }}
          data-testid="medical-history-button"
          leftIcon={Document}
          onClick={() =>
            window.open(
              `${config.APPS_SERVER}/admin/study/${studyId}/medical-history/radiologist`,
              '_blank',
            )
          }
          size="sm"
          variant="text"
        >
          Pt Hx
        </Button>
        {!isPreviewMode && previousStudyIds.length > 0 && (
          <div className="flex flex-row gap-3" data-testid="previous-study-reports">
            {previousStudyIds.map((id: string, index: number) => (
              <Button
                key={`previous-report-${id}`}
                aria-label="PreviousReport"
                className={{ root: 'bg-stone-700 px-3' }}
                data-testid={`previous-report-btn-${index}`}
                onClick={() =>
                  window.open(
                    `${config.APPS_SERVER}/radiologist/study/${id}/latest-pdf-report`,
                    '_blank',
                  )
                }
                size="sm"
                variant="text"
              >
                P{index + 1}
              </Button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
