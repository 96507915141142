export const SPONDYLOARTHROPATHY_CONDITIONS = [
  'spondyloarthropathy_of_the_cervical_spine',
  'spondyloarthropathy_of_the_thoracic_spine',
  'spondyloarthropathy_of_the_lumbar_spine',
];

export const SPONDYLOARTHROPATHY_LABELS: {
  [key: string]: string;
} = {
  clinical_notes_placeholder: 'Please give us as much information as you can',
  input_placeholder: 'Enter finding here (if applicable)',
  spondyloarthropathy_of_the_cervical_spine: 'Cervical region',
  spondyloarthropathy_of_the_lumbar_spine: 'Lumbar region',
  spondyloarthropathy_of_the_thoracic_spine: 'Thoracic region',
};

export const SPONDYLOARTHROPATHY_OUTPUT_LABELS: {
  [key: string]: string;
} = {
  spondyloarthropathy_of_the_cervical_spine: 'Spondyloarthropathy - Cervical',
  spondyloarthropathy_of_the_lumbar_spine: 'Spondyloarthropathy - Lumbar',
  spondyloarthropathy_of_the_thoracic_spine: 'Spondyloarthropathy - Thoracic',
};

export const SPONDY_FINDING = {
  conditionTemplate: {
    condition: 'Spondyloarthropathy',
    conditionDetail: [],
    normalStatementOutcome: {},
    organ: 'spine',
    uuid: 'Spondyloarthropathy-conditions',
  },
  renderedSummary: {
    actionDetails: '',
    actionSummary: '',
    additionalRecommendations: null,
    clinicalNotes: null,
    csdScore: 0,
    findingDetails: 'We detected evidence of spondyloarthropathy.',
    findingSummary: '',
    keyImages: [],
    newFinding: false,
    oncoradsScore: 0,
    reportFindings: [],
    reportFindingsUuids: [],
    reportUuid: '',
    status: '',
    uuid: 'Spondyloarthropathy-rendered-summary',
  },
};
