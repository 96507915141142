import { create } from 'zustand';

import { KeyImageLabel } from '@/types/keyImageLabel';

import { Finding, KeyImage, KeyImagesState, LocalKeyImage } from './useKeyImages.type';

export const useKeyImages = create<KeyImagesState>((set) => ({
  currentDroppableId: null,
  currentIndex: null,
  isGlobalKILoading: false,
  isKeyImagesError: false,
  isKISectionOpen: false,
  isManualAddition: false,
  isOutsideOfBox: false,
  keyImageLabels: [],
  keyImages: [],
  localKeyImages: {},

  openKISection: (open: boolean, finding?: Finding, isManualAddition?: boolean) =>
    set(() => ({
      currentDroppableId: finding?.id,
      currentIndex: finding?.index,
      isKISectionOpen: open,
      isManualAddition,
    })),

  selectedImage: null,

  setCurrentDroppableId: (currentDroppableId: null | string) => set({ currentDroppableId }),

  setIsGlobalKILoading: (isGlobalKILoading: boolean) => set({ isGlobalKILoading }),

  setIsKeyImagesError: (isKeyImagesError: boolean) => set({ isKeyImagesError }),

  setIsOutsideOfBox: (isOutsideOfBox: boolean) => set({ isOutsideOfBox }),

  setKeyImageLabels: (keyImageLabels: KeyImageLabel[]) => set({ keyImageLabels }),

  setKeyImages: (keyImages: KeyImage[]) => set({ keyImages }),

  setLocalKeyImages: (id: string, images: LocalKeyImage[]) =>
    set(
      (state) =>
        ({
          localKeyImages: {
            ...state.localKeyImages,
            [id]: images,
          },
        }) as KeyImagesState,
    ),

  setManualAddition: (isManualAddition: boolean) => set({ isManualAddition }),

  setSelectedImage: (selectedImage: KeyImage | null) => set({ selectedImage }),

  updateKeyImageLabel: (updatedLabel: KeyImageLabel) => {
    set((state) => {
      const updatedLabels = state.keyImageLabels.map((item) => ({
        ...item,
        label: item.uuid === updatedLabel.uuid ? updatedLabel.label : item.label,
      }));

      return {
        keyImageLabels: updatedLabels,
      };
    });
  },
}));
