import { useMutation } from '@tanstack/react-query';

import { API_ENDPOINTS } from '@/core/api/endpoints';
import { makeApiRequest } from '@/core/api/makeApiRequest';
import { HttpMethod } from '@/core/constants';
import { useKeyImages as useKeyImagesStore } from '@/store/useKeyImages/useKeyImages';

import { SeriesProps, SeriesRetrieveResponse } from './useSeries.type';

export const useSeries = () => {
  const { setIsGlobalKILoading, setIsKeyImagesError } = useKeyImagesStore();

  const mutation = useMutation({
    mutationFn: async ({ studyId }: SeriesProps) => {
      const novaradResponse = await makeApiRequest<SeriesRetrieveResponse>(
        'HI',
        API_ENDPOINTS.dicom.deleteSeries,
        HttpMethod.POST,
        {
          key: 'novarad_mark-up',
          studyID: studyId,
        },
      );
      const keyImagesResponse = await makeApiRequest<SeriesRetrieveResponse>(
        'HI',
        API_ENDPOINTS.dicom.deleteSeries,
        HttpMethod.POST,
        {
          key: 'key_images',
          studyID: studyId,
        },
      );

      const seriesRetrieve = await makeApiRequest<SeriesRetrieveResponse>(
        'HI',
        API_ENDPOINTS.dicom.seriesRetrieve,
        HttpMethod.POST,
        {
          series_desc_list: ['novarad_mark-up', 'key_images'],
          study_id: studyId,
        },
      );

      return {
        keyImages: keyImagesResponse.data,
        novaradMarkUp: novaradResponse.data,
        series: seriesRetrieve.data,
      };
    },
    onError: () => {
      setIsGlobalKILoading(false);
      setIsKeyImagesError(true);
    },
  });

  return mutation;
};
