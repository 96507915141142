import { QuestionCircle } from '@prenuvo/halo-icon';
import { IconSource } from '@prenuvo/halo-web';

import { SPONDYLOARTHROPATHY_CONDITIONS } from '@/components/SpondyConditions/constants';
import { OnDemandConditionsData } from '@/store/useOnDemandConditions/useOnDemandConditionsStore.types';
import { Finding } from '@/types/finding';
import { FormStructure } from '@/types/formStructure';
import { NormalizeCheckItem, ReportOrgan } from '@/types/shared';

export const extractValues = (inputString: string): string => {
  const pattern = /<{name: ".*?", value: "(.*?)"(, uuid: ".*?")?}>/g;
  const outputString = inputString.replace(pattern, (_, value) => value);

  return outputString;
};

export const splitFindingDetails = (details: string): string[] =>
  details
    .split(/\n/)
    .filter((detail) => detail.trim() !== '')
    .map((detail) => extractValues(detail));

export const getIconByFormStructure = (title: string, formStructure: FormStructure) => {
  const organ = formStructure.structure.find(
    (organData) => organData.key && organData.key === title,
  );

  return (organ?.icon || QuestionCircle) as IconSource;
};

export const getNormalStatementsWithDefaultTrue = (reportOrgan: ReportOrgan) => {
  if (!reportOrgan?.normaliseCheck) {
    return [];
  }

  return Object.entries(reportOrgan.normaliseCheck)
    .filter(
      ([key, statement]) =>
        key.startsWith('norm_') && (statement as NormalizeCheckItem).defaultBool === true,
    )
    .map(([, statement]) => (statement as NormalizeCheckItem).value);
};

export const mergeOrgansAndonDemandConditions = (
  organs: { [key: string]: Finding[] },
  onDemandConditons: {
    [key: string]: OnDemandConditionsData[];
  },
) =>
  Object.entries(onDemandConditons).reduce(
    (updatedOrgansData, [key, conditions]) => {
      const updatedOrgans = { ...updatedOrgansData };

      updatedOrgans[key] = conditions.reduce((organData, condition) => {
        const updatedOrganData = [...organData];

        if (condition.renderedSummary) {
          const updatedData = {
            conditionTemplate: { organ: key },
            error: null,
            observation: '',
            progress: false,
            renderedSummary: condition.renderedSummary,
          };

          const index = updatedOrganData.findIndex(
            (organ) => organ.renderedSummary.uuid === condition.renderedSummary!.uuid,
          );

          if (index !== -1) {
            updatedOrganData[index] = updatedData;
          } else {
            updatedOrganData.push(updatedData);
          }
        }

        return updatedOrganData;
      }, updatedOrgans[key] || []);

      return updatedOrgans;
    },
    { ...organs },
  );

export const sortSpondyConditions = (findings: Finding[]): Finding[] =>
  findings.sort((a, b) => {
    const aIndex = SPONDYLOARTHROPATHY_CONDITIONS.indexOf(a.conditionTemplate.condition!);
    const bIndex = SPONDYLOARTHROPATHY_CONDITIONS.indexOf(b.conditionTemplate.condition!);

    if (aIndex === -1 && bIndex === -1) {
      return 0;
    }

    if (aIndex === -1) {
      return 1;
    }

    if (bIndex === -1) {
      return -1;
    }

    return aIndex - bIndex;
  });

export const sortAndGroupFindings = (findings: Finding[]) => {
  const spondyloarthropathyFindings: Finding[] = [];
  const otherFindings: Finding[] = [];
  let startIndex = 0;
  let indexUpdated = false;

  findings.forEach((finding, index) => {
    if (SPONDYLOARTHROPATHY_CONDITIONS.includes(finding.conditionTemplate.condition!)) {
      if (!indexUpdated) {
        startIndex = index;
        indexUpdated = true;
      }

      spondyloarthropathyFindings.push(finding);
    } else {
      otherFindings.push(finding);
    }
  });

  spondyloarthropathyFindings.sort(
    (a, b) =>
      SPONDYLOARTHROPATHY_CONDITIONS.indexOf(a.conditionTemplate.condition!) -
      SPONDYLOARTHROPATHY_CONDITIONS.indexOf(b.conditionTemplate.condition!),
  );

  if (spondyloarthropathyFindings.length > 0) {
    const updatedFindings = [
      ...otherFindings.slice(0, startIndex),
      ...spondyloarthropathyFindings,
      ...otherFindings.slice(startIndex),
    ];

    return updatedFindings;
  }

  return otherFindings;
};

export const mergeDefaultAndCommonFindings = (
  defaultFindings: { [key: string]: Finding[] },
  commonFindings: { [key: string]: Finding[] },
) => {
  const result = { ...defaultFindings };

  Object.entries(commonFindings).forEach(([key, findings]) => {
    let updatedFindings = [...findings];

    if (key === 'spine') {
      updatedFindings = sortAndGroupFindings(updatedFindings);
    }

    if (!result[key]) {
      result[key] = updatedFindings;
    } else {
      result[key] = [...result[key], ...updatedFindings];
    }
  });

  return result;
};
