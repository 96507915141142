import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { WarningTriangle } from '@prenuvo/halo-icon';
import { Button, Dialog, Icon, Typography } from '@prenuvo/halo-web';

import { Loading } from '@/components';
import {
  getNormalStatementsWithDefaultTrue,
  mergeDefaultAndCommonFindings,
} from '@/components/Report/utils/Report.util';
import { useReportOrgan } from '@/hooks/queries';
import { useDefaultFinding, useFinding } from '@/store';
import { useFormStructure } from '@/store/useFormStructure/useFormStructure';
import { useOrganValidation } from '@/store/useOrganValidation/useOrganValidation';
import { useReport } from '@/store/useReport/useReport';
import { ReportOrgan } from '@/types/shared';
import { getOrgansWithHighScores, transformSnakeCaseToPascalCase } from '@/utils/utils';

import './finalizationmodal.css';

import { ONCORAD } from '../../../../assets/icons';
import { Normals } from '../Normals/Normals';
import { FinalizationModalProps } from './FinalizationModal.types';

export function FinalizationModal({
  setShowFinalizationModal,
  showFinalizationModal,
}: FinalizationModalProps) {
  const navigate = useNavigate();
  const scrollbarRef = useRef<HTMLDivElement | null>(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const { id: studyId } = useParams();
  const { setPreviewMode } = useReport();
  const { findings } = useFinding();
  const { defaultFindings } = useDefaultFinding();
  const { formStructure } = useFormStructure();
  const { organs } = useOrganValidation();
  const { report } = useReport();
  const { isLoading, reportOrgan } = useReportOrgan(report?.uuid || '');
  const organsWithHighScore = getOrgansWithHighScores(
    mergeDefaultAndCommonFindings(defaultFindings, findings),
  );
  const [normalsByOrgan, setNormalsByOrgan] = useState<Record<string, string[]>>({});
  const navigateToPreview = () => {
    navigate(`/reporting/${studyId}/preview`);
    setShowFinalizationModal(false);
    setPreviewMode(true);
  };

  useEffect(() => {
    if (reportOrgan && Array.isArray(reportOrgan) && reportOrgan.length > 0) {
      reportOrgan.forEach((normals: ReportOrgan) => {
        setNormalsByOrgan((prev) => ({
          ...prev,
          [normals.organ]: getNormalStatementsWithDefaultTrue(normals as ReportOrgan),
        }));
      });
    }
  }, [reportOrgan]);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollbarRef.current) {
        const { clientHeight, scrollHeight, scrollTop } = scrollbarRef.current;

        if (scrollTop + clientHeight >= scrollHeight - 10) {
          setIsButtonDisabled(false);
        }
      }
    };

    const scrollbarElement = scrollbarRef.current;

    if (scrollbarElement) {
      handleScroll();
      scrollbarElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollbarElement) {
        scrollbarElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [scrollbarRef.current]);

  const isNormalExits = (organ: string) =>
    !organs[organ].done && normalsByOrgan[organ] && normalsByOrgan[organ].length > 0;

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Dialog
      aria-describedby="finalization-modal"
      className={{
        content: 'h-auto w-[1024px] max-w-max',
        header:
          'items-center bg-stone-950  p-5 text-lg text-neutral-50 dark:bg-stone-950 dark:text-neutral-50',
        title: 'text-md md:text-md mr-10 font-normal',
      }}
      data-testid="finalization-modal"
      description={{
        children: '',
      }}
      onOpenChange={(value) => setShowFinalizationModal(value)}
      open={showFinalizationModal}
      showClose
      title={{
        children:
          'Confirm that any ONCO / CSD scores of 4 or greater and normal statements of organs you missed, are correct.',
      }}
    >
      <div className="flex h-auto w-full flex-col rounded-b-[24px] bg-stone-950 p-5 pt-0 dark:bg-stone-950">
        <div className="custom-scrollbar max-h-[500px] overflow-y-auto" ref={scrollbarRef}>
          <div>
            {formStructure?.structure.map((organData) => {
              if (isNormalExits(organData.key) || organsWithHighScore[organData.key]) {
                return (
                  <div key={organData.key} className="border-b px-2 py-4">
                    <div className="flex flex-row">
                      {organData.title}{' '}
                      {organsWithHighScore[organData.key] && (
                        <span className="ml-2">
                          <img alt="ONCORAD" src={ONCORAD} />
                        </span>
                      )}
                    </div>
                    {organsWithHighScore[organData.key]?.map((finding) => (
                      <div
                        key={finding.conditionTemplate.condition}
                        className="flex flex-row items-center pt-2 text-orange-400 dark:text-orange-400"
                      >
                        <Icon
                          aria-label="warning"
                          className="mr-[12px] fill-orange-400"
                          size="sm"
                          source={WarningTriangle}
                        />
                        {transformSnakeCaseToPascalCase(finding.conditionTemplate.condition!)} -
                        Finding marked as
                        {finding.renderedSummary.oncoradsScore >= 4 &&
                          ` ONCO-${finding.renderedSummary.oncoradsScore}`}
                        {finding.renderedSummary.csdScore >= 4 &&
                          finding.renderedSummary.oncoradsScore >= 4 &&
                          ','}
                        {finding.renderedSummary.csdScore >= 4 &&
                          ` CSD-${finding.renderedSummary.csdScore}`}
                      </div>
                    ))}
                    {!organsWithHighScore[organData.key] && isNormalExits(organData.key) && (
                      <Normals normals={normalsByOrgan[organData.key]} />
                    )}
                  </div>
                );
              }

              return null;
            })}
          </div>
        </div>
        <div className="mt-6 flex items-center justify-end">
          <Typography as="label" className="mr-4 text-neutral-400 dark:text-neutral-400">
            By clicking Preview, I confirm the above is correct
          </Typography>
          <Button
            data-testid="finalization-preview-report"
            disabled={isButtonDisabled}
            onClick={navigateToPreview}
          >
            Preview Report
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
