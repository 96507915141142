export type DefaultQuestions = Record<
  string,
  Record<
    string,
    {
      header?: string;
      order: number;
      questions?: Record<
        string,
        {
          default_value?: string;
          label?: string;
        }
      >;
      required?: boolean;
    }
  >
>;

export const DEFAULT_QUESTIONS: DefaultQuestions = {
  breasts: {
    default_question_amount_of_fibroglandular_tissue: {
      order: 2,
      questions: {
        tissue_density: {
          label: 'Fibroglandular Tissue (FGT)',
        },
      },
      required: true,
    },
    default_question_mastectomy: {
      order: 1,
      questions: {
        laterality: {
          label: 'Mastectomy Laterality',
        },
      },
      required: true,
    },
  },
  gallbladder_and_biliary_system: {
    default_question_gallbladder_appearance: {
      order: 1,
      required: true,
    },
  },
  ovaries: {
    default_question_ovary_appearance: {
      order: 1,
      required: true,
    },
  },
  prostate: {
    default_question_total_size_of_prostate: {
      order: 1,
      required: true,
    },
  },
  spine: {
    default_question_conus_level: {
      order: 2,
    },
    default_question_vertebrae_numbering: {
      header: 'Spine count',
      order: 1,
      questions: {
        number_of_c_spine_vertebrae: {
          default_value: '7',
          label: '# of C-spine vertebrae',
        },
        number_of_l_spine_vertebrae: {
          default_value: '5',
          label: '# of L-spine vertebrae',
        },
        number_of_t_spine_vertebrae: {
          default_value: '12',
          label: '# of T-spine vertebrae',
        },
      },
      required: true,
    },
  },
  uterus: {
    default_question_c_section_scar: {
      order: 2,
    },
    default_question_endometrial_thickness: {
      order: 4,
    },
    default_question_intrauterine_device: {
      order: 3,
    },
    default_question_uterus_appearance: {
      order: 1,
    },
  },
};

export const PARTIALLY_DISABLED_CONDITIONS = {
  gallbladder_and_biliary_system: [
    'adenomyomatosis',
    'gallbladder_septation',
    'phrygian_cap',
    'cholecystitis',
    'gallstones',
  ],
};
