import { useMutation } from '@tanstack/react-query';

import { ReportPdf } from '@/components/Report/Report.type';
import { API_ENDPOINTS } from '@/core/api/endpoints';
import { makeApiRequest } from '@/core/api/makeApiRequest';
import { HttpMethod } from '@/core/constants';

export const useReportPdf = () => {
  const mutation = useMutation({
    mutationFn: async (reportPdfObj: Partial<ReportPdf>) => {
      const { data } = await makeApiRequest<ReportPdf>(
        'FCT',
        API_ENDPOINTS.reportPdf.createReportPdf,
        HttpMethod.POST,
        reportPdfObj,
      );

      return data;
    },
  });

  return mutation;
};
