/* eslint-disable no-console */
import { useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { API_ENDPOINTS } from '@/core/api/endpoints';
import { makeApiRequest } from '@/core/api/makeApiRequest';
import { IS_DEV_OR_LOCAL_ENV } from '@/mocks/constants';
import { StudiesResponse } from '@/types/study';

export const usePatientStudies = (
  patientID: string,
  studyId: string | undefined,
  scanDate: number,
) => {
  const hasPatientStudiesFetched = useRef(false);
  const [previousStudyIds, setPreviousStudyIds] = useState<string[]>([]);
  const url = !IS_DEV_OR_LOCAL_ENV
    ? `${API_ENDPOINTS.study.getStudy}?users=${patientID}&status=scanned`
    : `${API_ENDPOINTS.study.getStudy}/${studyId}`;

  const options = !IS_DEV_OR_LOCAL_ENV ? !!patientID : !!studyId;

  const fetchPatientStudies = () => makeApiRequest<StudiesResponse>('APPS', url, 'GET');

  const {
    data: patientStudiesData,
    error,
    isLoading,
  } = useQuery({
    enabled: options && !hasPatientStudiesFetched.current,
    queryFn: fetchPatientStudies,
    queryKey: ['patientStudies', patientID],
    refetchOnWindowFocus: false,
    retryOnMount: false,
  });

  useEffect(() => {
    if (patientStudiesData) {
      hasPatientStudiesFetched.current = true;

      if (patientStudiesData?.data?.studies?.length > 0) {
        const previousStudies = patientStudiesData?.data?.studies?.filter(
          (study) => study.studyID !== studyId && study.booking[0].utcStart < scanDate,
        );

        if (previousStudies?.length > 0) {
          const sortedStudies = previousStudies
            .sort((a, b) => b.booking[0].utcStart - a.booking[0].utcStart)
            .slice(0, 8);

          setPreviousStudyIds(sortedStudies.map((study) => study.studyID));
        } else {
          console.log('No previous studies found');
        }
      } else {
        console.log('No patient studies found');
      }
    }
  }, [patientStudiesData, hasPatientStudiesFetched]);

  return { error, isLoading, previousStudyIds };
};
