import { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Typography, useToaster } from '@prenuvo/halo-web';

import { Header, NavMenu } from '@/components';
import { FinalizationModal } from '@/components/FinalizationModal';
import { useSeries } from '@/hooks/mutations/useSeries/useSeries';
import {
  usePatientStudies,
  useReport,
  useScanDetails,
  useStudyMedicalHistory,
} from '@/hooks/queries';
import { useFormStructure as useFormStructureQuery } from '@/hooks/queries/useFormStructure/useFormStructure';
import { useKeyImagesQuery } from '@/hooks/queries/useKeyImagesQuery';
import { useMedicalHistory } from '@/hooks/queries/useMedicalHistory/useMedicalHistory';
import { useDefaultFinding, useFinding, useHiStudy } from '@/store';
import { useKeyImages as useKeyImagesStore } from '@/store/useKeyImages/useKeyImages';
import { useOrganValidation } from '@/store/useOrganValidation/useOrganValidation';
import { OrganValidationStatus } from '@/store/useOrganValidation/useOrganValidation.types';
import { useReport as useReportStore } from '@/store/useReport/useReport';
import { Finding } from '@/types/finding';
import {
  findMissingRequiredDetails,
  formatEditorString,
  getOrgansWithHighScores,
  mergeFindings,
} from '@/utils/utils';

import { ErrorWithoutBackground } from '../assets/icons';

export function MainLayout() {
  const navigate = useNavigate();

  const { id: studyId, organ } = useParams();
  const location = useLocation();

  const [isNavCollapsed, setIsNavCollapsed] = useState(false);
  const [selectedLink, setSelectedLink] = useState<string>('');
  const { scanDetails } = useHiStudy();
  const { patientID, scanDate } = scanDetails;

  const { data: scanDetailsData } = useScanDetails(studyId);
  const { formStructure, isLoading: isLoadingFormStructure } = useFormStructureQuery(
    studyId || '',
    scanDetailsData?.skuID || '',
  );

  useReport();
  useMedicalHistory(studyId);
  useStudyMedicalHistory(studyId);
  usePatientStudies(patientID, studyId, scanDate);

  const { findings } = useFinding();
  const { defaultFindings } = useDefaultFinding();
  const { getStatus, organs, setStatus } = useOrganValidation();
  const { addToast } = useToaster();
  const toastRef = useRef<HTMLSpanElement>(null);
  const { report, setPreviewMode } = useReportStore();
  const [hasError, setHasError] = useState(false);
  const [isReviewClicked, setIsReviewClicked] = useState(false);
  const { isGlobalKILoading, keyImageLabels, keyImages, setKeyImages } = useKeyImagesStore();
  const [showFinalizationModal, setShowFinalizationModal] = useState(false);

  const { data: keyImagesData, refetch: refetchKeyImages } = useKeyImagesQuery(studyId || '', {
    allowRetries: true,
    enabled: true,
  });

  const seriesMutation = useSeries();

  useEffect(() => {
    if (keyImagesData) {
      const mappedKeyImages = keyImagesData.key_images.instanceNumbers.map((instanceNumber) => ({
        id: keyImagesData.key_images.imageKeys[instanceNumber],
        image: keyImagesData.images[instanceNumber],
        instanceNumber,
      }));

      setKeyImages(mappedKeyImages);
    }
  }, [keyImagesData]);

  useEffect(() => {
    if (isGlobalKILoading) {
      seriesMutation.mutate({ studyId: studyId || '' });
    }
  }, [isGlobalKILoading]);

  useEffect(() => {
    if (!!keyImages && seriesMutation.isSuccess && studyId) {
      refetchKeyImages({ throwOnError: true });
    }
  }, [seriesMutation.isSuccess, studyId, refetchKeyImages]);

  const setOrganStateHandler = (data: { [key: string]: Finding[] }) => {
    Object.keys(data).forEach((organData) => {
      if (organData === 'reason_for_scan' || organData === 'retrieve_key_images') {
        setStatus(organData, { done: true, error: false, warning: false });
      } else if (
        organData === 'favorite' &&
        formatEditorString(report?.finalImpressions ?? '') !== ''
      ) {
        setStatus(organData, { done: true, error: false, warning: false });
      } else {
        const status = getStatus(organData);

        setStatus(organData, { done: status?.done ?? false, error: false, warning: false });
      }
    });

    const missingRequiredFields = findMissingRequiredDetails(data, isReviewClicked);

    Object.keys(missingRequiredFields).forEach((organData) => {
      if (missingRequiredFields[organData]) {
        const status = getStatus(organData)!;

        setStatus(organData, { ...status, error: true });
      }
    });

    const highScoreOrganData = getOrgansWithHighScores(data);

    Object.keys(highScoreOrganData).forEach((organData) => {
      if (highScoreOrganData[organData]) {
        const status = getStatus(organData)!;

        setStatus(organData, { ...status, warning: true });
      }
    });
  };

  const initializeOrganState = () => {
    formStructure?.structure.forEach((organData) => {
      const currentOrganState = getStatus(organData.key);

      if (!currentOrganState) {
        setStatus(organData.key, { done: false, error: false, warning: false });
      } else {
        setStatus(organData.key, { ...currentOrganState });
      }
    });

    const mergedFindingsData = formStructure?.structure
      ? mergeFindings(formStructure.structure, defaultFindings, findings)
      : {};

    setOrganStateHandler(mergedFindingsData);
  };

  useEffect(() => {
    if (
      Object.keys(findings).length > 0 ||
      formStructure?.structure ||
      Object.keys(defaultFindings).length > 0
    ) {
      initializeOrganState();
    }
  }, [findings, formStructure?.structure, defaultFindings]);

  useEffect(() => {
    const pathMap = {
      'entry/final-impressions': 'favorite',
      'entry/key-images': 'key_images',
      'entry/retrieve-key-images': 'retrieve_key_images',
    };

    const selectedLinkValue = organ
      ? organ.replace(/-/g, '_')
      : Object.entries(pathMap).find(([path]) => location.pathname.includes(path))?.[1] ||
        'reason_for_scan';

    setSelectedLink(selectedLinkValue);
  }, [organ, location]);

  const onReviewSignOrganValidation = (organsData: Record<string, OrganValidationStatus>) => {
    const updatedOrgansData = { ...organsData };

    const mergedFindingsData = formStructure?.structure
      ? mergeFindings(formStructure.structure, defaultFindings, findings)
      : {};

    const missingRequiredFields = findMissingRequiredDetails(mergedFindingsData, true);

    Object.entries(missingRequiredFields).forEach(([organData, isMissing]) => {
      if (isMissing) {
        updatedOrgansData[organData] = { ...updatedOrgansData[organData], error: true };
        setStatus(organData, { ...updatedOrgansData[organData] });
      }
    });

    if (
      updatedOrgansData?.favorite &&
      !updatedOrgansData.favorite?.done &&
      formatEditorString(report?.finalImpressions ?? '') === ''
    ) {
      updatedOrgansData.favorite = { ...updatedOrgansData.favorite, error: true };
      setStatus('favorite', { ...updatedOrgansData.favorite });
    }

    return Object.values(updatedOrgansData).every((status) => !status.error);
  };

  const toastHandler = () => {
    if (toastRef.current) {
      return;
    }

    addToast({
      className: {
        root: 'w-[260px] ml-auto',
      },
      description: {
        children: (
          <span className="flex flex-row gap-4 p-1" ref={toastRef}>
            <img alt="missing input" src={ErrorWithoutBackground} />
            <Typography className="text-red-500 dark:text-red-500">
              Fill out required fields <br /> before proceeding
            </Typography>
          </span>
        ),
      },
      duration: 3000,
      variant: 'error',
    });
  };

  const handleLinkSelect = (link: string) => {
    setHasError(false);
    setSelectedLink(link);

    const linkMap = {
      favorite: `/reporting/${studyId}/entry/final-impressions`,
      key_images: `/reporting/${studyId}/entry/key-images`,
      reason_for_scan: `/reporting/${studyId}/entry/reason-for-scan`,
      retrieve_key_images: `/reporting/${studyId}/entry/retrieve-key-images`,
    };

    const path =
      linkMap[link as keyof typeof linkMap] ||
      `/reporting/${studyId}/entry/organ/${link.replace(/_/g, '-')}`;

    navigate(path);
  };

  const navigateToOrgan = (organData: Record<string, OrganValidationStatus>) => {
    const errorOrgan =
      formStructure?.structure &&
      formStructure?.structure.find(
        (organObj) => organData[organObj.key] && organData[organObj.key].error,
      );

    if (errorOrgan) {
      handleLinkSelect(errorOrgan.key);
    }
  };

  useEffect(() => {
    if (hasError) {
      navigateToOrgan(organs);
    }
  }, [hasError, organs]);

  const validateOncoradWarning = (organData: Record<string, OrganValidationStatus>) =>
    Object.keys(organData).some((organKey) => organData[organKey].warning);

  const navigateToPreview = () => {
    setIsReviewClicked(true);

    if (!onReviewSignOrganValidation(organs)) {
      toastHandler();
      setHasError(true);

      return;
    }

    if (
      validateOncoradWarning(organs) ||
      Object.keys(organs).some((organKey) => !organs[organKey].done)
    ) {
      setShowFinalizationModal(true);

      return;
    }

    setHasError(false);
    setPreviewMode(true);
    navigate(`/reporting/${studyId}/preview`);
  };

  const toggleSideMenuHandler = () => {
    setIsNavCollapsed((prevState) => !prevState);
  };

  useEffect(() => {
    if (!keyImages.length || !isReviewClicked) {
      return;
    }

    const excludedSet = new Set(report?.excludeDicomImages || []);
    const labeledSet = new Set(keyImageLabels.map((label) => label.imageKey));
    const processedCount = keyImages.reduce(
      (count, image) => count + (excludedSet.has(image.id) || labeledSet.has(image.id) ? 1 : 0),
      0,
    );

    setStatus('key_images', {
      done: processedCount === keyImages.length,
      error: processedCount !== keyImages.length,
      warning: false,
    });
  }, [isReviewClicked, keyImageLabels, report?.excludeDicomImages, keyImages]);

  return (
    <div className="flex h-screen flex-col">
      <Header />
      <div className="flex h-[calc(100%-5rem)] p-4 pb-0 pr-0">
        <NavMenu
          currentOrgan={organ?.replace(/-/g, '_')}
          isCollapsed={isNavCollapsed}
          isLoading={isLoadingFormStructure}
          links={formStructure?.structure || []}
          maxSize="202"
          minSize="80"
          onLinkSelect={handleLinkSelect}
          onReviewClick={navigateToPreview}
          onToggleSideMenu={toggleSideMenuHandler}
          selectedLinkValue={selectedLink}
        />
        <div className="custom-scrollbar relative flex-1 overflow-y-auto ">
          <Outlet />
        </div>
      </div>
      {showFinalizationModal && (
        <FinalizationModal
          setShowFinalizationModal={setShowFinalizationModal}
          showFinalizationModal={showFinalizationModal}
        />
      )}
    </div>
  );
}
