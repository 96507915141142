import { cn, Typography } from '@prenuvo/halo-web';

import { Editor } from '@/components/Editor';
import { useReportOrgan as useReportOrganQuery } from '@/hooks/queries';
import { CSD_MESSAGES } from '@/mocks/constants';
import { useReport } from '@/store';
import { replaceConditionWithValue, transformSentenceToKebabCase } from '@/utils/utils';

import { FindingProps } from './Finding.type';

export function Finding({ finding }: FindingProps) {
  const formattedOrganTitle = transformSentenceToKebabCase(finding.organ);
  const { actionDetails, actionSummary, findingDetails, findingSummary } = finding;
  const isEditorReadOnly = true;
  const { report } = useReport();

  useReportOrganQuery(report?.uuid || '', undefined);

  return (
    <div
      className="mt-3 space-y-4 border-b border-stone-700 pb-6"
      data-testid={`${formattedOrganTitle}-finding`}
    >
      <div className="space-y-1">
        {findingSummary && (
          <div className="text-lg font-bold" data-testid="finding-summary">
            <Editor
              isReadOnly={isEditorReadOnly}
              value={replaceConditionWithValue(findingSummary)}
            />
          </div>
        )}
        {finding.csdScore !== 0 && (
          <p
            className={`text-sm font-semibold ${CSD_MESSAGES[finding.csdScore as keyof typeof CSD_MESSAGES].color}`}
          >
            {CSD_MESSAGES[finding.csdScore as keyof typeof CSD_MESSAGES].message}
          </p>
        )}
      </div>
      {findingDetails.length > 1 && (
        <div>
          <div data-testid="finding-detail">
            <Editor
              isReadOnly={isEditorReadOnly}
              value={replaceConditionWithValue(findingDetails)}
            />
          </div>
        </div>
      )}
      <div
        className={cn('rounded-xl bg-neutral-950 p-4', {
          hidden: !actionSummary && !actionDetails,
        })}
      >
        {actionSummary && (
          <div className="mb-3 text-lg font-extrabold" data-testid="action-summary">
            <Editor
              isReadOnly={isEditorReadOnly}
              value={replaceConditionWithValue(actionSummary)}
            />
          </div>
        )}
        {actionDetails && (
          <div data-testid="action-detail">
            <Editor
              isReadOnly={isEditorReadOnly}
              value={replaceConditionWithValue(actionDetails)}
            />
          </div>
        )}
      </div>
      <div className="mt-2">
        {finding?.keyImages?.length > 0 && (
          <Typography>
            {finding?.keyImages
              ?.filter(
                (keyImage) =>
                  keyImage.status !== 'deprecated' &&
                  (keyImage.imageKey !== null || keyImage.instanceKey !== null),
              ).reduce((acc: typeof finding.keyImages, current: typeof finding.keyImages[0]) => {
                const x = acc.find(item => item.imageKey === current.imageKey && item.instanceKey === current.instanceKey);

                if (!x) {
                  return acc.concat([current]);
                }
 
                return acc;
              }, [])
              .map((keyImage, index, filteredArray) => (
                <span key={keyImage.uuid}>
                  {index === 0 && 'See '}
                  Figure {keyImage.instanceKey}
                  {index < filteredArray.length - 1 ? ', ' : '.'}
                </span>
              ))}
          </Typography>
        )}
      </div>
    </div>
  );
}
