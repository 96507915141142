import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Button, Typography } from '@prenuvo/halo-web';

import { SignatureProps } from './Signature.types';

export const Signature = forwardRef(
  ({ className, description, onFinishSigning, signatureData, title }: SignatureProps, ref) => {
    const signatureCanvasRef = useRef<null | SignatureCanvas>(null);

    useEffect(() => {
      if (signatureData && signatureCanvasRef.current) {
        const canvas = signatureCanvasRef.current;
        const img = new Image();

        img.onload = () => {
          canvas.clear();

          const canvasElement = canvas.getCanvas();
          const ctx = canvasElement.getContext('2d');

          const canvasWidth = canvasElement.width;
          const canvasHeight = canvasElement.height;

          const scaleX = canvasWidth / img.width;
          const scaleY = canvasHeight / img.height;

          const scale = Math.min(scaleX, scaleY) * 0.5;

          const scaledWidth = img.width * scale;
          const scaledHeight = img.height * scale;

          const x = (canvasWidth - scaledWidth) / 6;
          const y = (canvasHeight - scaledHeight) / 6;

          if (ctx) {
            ctx.clearRect(0, 0, canvasWidth, canvasHeight);

            ctx.drawImage(img, x, y, scaledWidth, scaledHeight);
          }

          canvas.off();
          onFinishSigning(true);
        };

        img.src = `data:image/png;base64,${signatureData}`;
      }
    }, [signatureData]);

    const clearSignature = () => {
      signatureCanvasRef.current?.clear();
      signatureCanvasRef.current?.on();
      onFinishSigning(false);
    };

    const getSignature = () => {
      const signature = signatureCanvasRef.current?.toDataURL();

      return signature;
    };

    useImperativeHandle(ref, () => ({
      getSignature,
    }));

    return (
      <div className={className}>
        {title && <Typography variant="h5">{title}:</Typography>}
        {description && (
          <Typography
            className="pt-2 text-neutral-400 dark:text-neutral-400"
            variant="paragraph-sm"
          >
            {description}:
          </Typography>
        )}
        <div className="relative my-6 h-[164px]">
          <SignatureCanvas
            canvasProps={{
              className: 'w-full h-full bg-neutral-50 dark:bg-neutral-50 rounded-2xl',
            }}
            clearOnResize={false}
            data-testid="signature-canvas"
            onEnd={() => onFinishSigning(true)}
            penColor="black"
            ref={signatureCanvasRef}
          />
          <Button
            className={{ root: 'absolute bottom-2 right-1 dark:text-black dark:hover:bg-white' }}
            data-testid="clear-signature-button"
            onClick={clearSignature}
            size="sm"
            variant="text"
          >
            Clear Signature
          </Button>
        </div>
      </div>
    );
  },
);

Signature.displayName = 'Signature';
