export enum Progress {
  IN_PROGRESS = 'InProgress',
  ERROR = 'Error',
  COMPLETED = 'Completed',
  MISSING_INPUT = 'missingInput',
  ONCORAD = 'oncorad',
}

export type NavListSubProps = {
  disabled?: boolean;
  key: string;
  value: string;
};

export type NavListProps = {
  disabled?: boolean;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | string;
  key: string;
  progress: null | Progress;
  sub?: NavListSubProps[];
  title: string;
};

export type NavMenuProps = {
  currentOrgan?: string;
  isCollapsed: boolean;
  isLoading?: boolean;
  isReviewVisible?: boolean;
  links: NavListProps[];
  maxSize: string;
  minSize: string;
  onLinkSelect?: (link: string) => void;
  onReviewClick?: () => void;
  onToggleSideMenu: () => void;
  selectedLinkValue?: string;
};

export type ProgressIndicatorProps = {
  isCollapsed: boolean;
  progress: null | Progress;
};
