import { useMutation } from '@tanstack/react-query';

import { API_ENDPOINTS } from '@/core/api/endpoints';
import { makeApiRequest } from '@/core/api/makeApiRequest';
import { HttpMethod } from '@/core/constants';

export const useSaveTechniqueNotes = (studyId: string) => {
  const saveTechniqueNote = async (notes: { technique?: string; techniqueNotes?: string }) => {
    const { data } = await makeApiRequest(
      'FCT',
      `${API_ENDPOINTS.report.saveReport}/${studyId}`,
      HttpMethod.PATCH,
      notes,
    );

    return data;
  };

  const mutation = useMutation({
    mutationFn: (techniqueNote: { technique?: string; techniqueNotes?: string }) =>
      saveTechniqueNote(techniqueNote),
  });

  return mutation;
};
